import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you

import { MoreHoriz, Person } from '@mui/icons-material';
import { Button, IconButton, keyframes, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { PayloadAction } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import supersub from 'remark-supersub';

import WmCompass from '../../assets/wm-compass.png';
import {
  getConversations,
  postConvoFromMessage,
  regenerateMessage,
} from '../../redux/actions/conversationActions';
import { selectAppStatus } from '../../redux/reducers/appStatusReducer';
import { selectConversations, setIsLoading } from '../../redux/reducers/conversationReducer';
import { AppDispatch } from '../../redux/store';
import { AzureIndexCitation, ToolMessageContent } from '../../Types/azureIndexCitations';
import { Conversation, Message, MessageFile } from '../../Types/conversation';
import { AppEnum, Llm, MessageFrom } from '../../Types/enums';
import { Prompt } from '../../Types/prompt';
import MarkdownTable from '../MarkdownTable';
import { useAnalytics } from '../Providers/AnalyticsProvider';
import CodeCopyButton from './CodeCopyButton';
import FileCard from './FileCard';

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)({
  borderRadius: '4px',
});

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ChatBubbleContainer = styled('div')({
  fontSize: '14px',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const ChatIcon = styled('img')({
  width: '20px',
});

const SpinningChatIcon = styled(ChatIcon)({
  animation: `${rotateAnimation} 2s linear infinite`,
});

const PersonIcon = styled(Person)({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
});

const CodePre = styled('pre')({
  position: 'relative',
  overflowX: 'auto',
});

const ChatCard = styled('div')(({ sender }: { sender: MessageFrom }) => ({
  borderRadius: '4px',

  margin: '2px 0',
  outline: 0,
  WebkitTapHighlightColor: 'transparent',
  WebkitTextDecoration: 'none',
  textDecoration: 'none',
  color: '#000',
  boxSizing: 'border-box',
  position: 'relative',
  padding: '1rem',
  backgroundColor: sender === 'AI' ? '#fff' : '#FAF8F2',
}));

const ProfilePhoto = styled('img')({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
});

function parseMessage(message: string, azureIndexCitations: AzureIndexCitation[]) {
  let messageText = message;
  const citationLinks = messageText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = '[doc'.length;
  const filteredCitations = [] as AzureIndexCitation[];
  let citationReindex = 0;
  citationLinks?.forEach((link) => {
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const citation = azureIndexCitations[Number(citationIndex) - 1] as AzureIndexCitation;
    if (!filteredCitations.find((c) => c.id === citationIndex) && citation) {
      messageText = messageText.replaceAll(link, ` ^${++citationReindex}^ `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
    }
  });

  if (filteredCitations.length === 1) {
    messageText += '\n\n  **Citation:**';
  } else if (filteredCitations.length > 1) {
    messageText += '\n\n  **Citations:**';
  }

  return { messageText, filteredCitations };
}

const renderMessageWithCitations = (
  message: string,
  citations: string[],
  azureIndexCitations: string
) => {
  if (citations && citations.length > 0) {
    const formatToMarkdown = (text: string, links: string[]): string => {
      const markdownLinks = links.map((citation) => `- ${citation}`).join('\n');
      return `${text}\n\nCitations:\n${markdownLinks}\n`;
    };
    return formatToMarkdown(message, citations);
  }

  if (azureIndexCitations && azureIndexCitations.length > 0) {
    const toolMessage: ToolMessageContent = JSON.parse(azureIndexCitations) as ToolMessageContent;
    const { messageText, filteredCitations } = parseMessage(message, toolMessage.citations);
    return (
      <>
        <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>{messageText}</ReactMarkdown>
        {filteredCitations.map((citation, index) => (
          <div key={index} style={{ padding: '5px' }}>
            <FileCard azureCitations={citation} />
          </div>
        ))}
      </>
    );
  }
  return message;
};

function convertLatexDelimiters(text: string): string {
  // Function to check if content looks like a formula
  const isLikelyFormula = (content: string): boolean => {
    // Check for common math symbols and patterns
    const mathPatterns = [
      /[+*/^=<>≤≥≈≠∑∏∫√]/, // Basic math operators and symbols
      /\\[a-zA-Z]+/, // LaTeX commands
      /[a-zA-Z]_\{[^}]+\}/, // Subscripts
      /[a-zA-Z]\^[2-9]/, // Superscripts
      /\{.+\}/, // Curly braces (common in LaTeX)
      /\\frac/, // Fractions
    ];
    return mathPatterns.some((pattern) => pattern.test(content));
  };

  // Replace display math \[...\]
  text = text.replace(/\\\[([\s\S]*?)\\\]/g, (_, content) => {
    return `$${content.trim()}$`;
  });

  // Replace inline math \(...\) and (likely formula)
  text = text.replace(/\\\(([^()]*(?:\([^()]*\)[^()]*)*)\\\)/g, (match, p1, p2) => {
    if (p1) {
      // This was \(...\)
      return `$${p1.trim()}$`;
    } else if (isLikelyFormula(p2)) {
      // This was (...) and looks like a formula
      return `$${p2.trim()}$`;
    } else {
      // This was (...) but doesn't look like a formula
      return match;
    }
  });

  return text;
}

const renderMessage = (message: string, citations: string[]) => {
  message = convertLatexDelimiters(message);
  if (citations && citations.length > 0) {
    const formatToMarkdown = (text: string, links: string[]): string => {
      const markdownLinks = links.map((citation) => `- ${citation}`).join('\n');
      return `${text}\n\nCitations:\n${markdownLinks}\n`;
    };
    return formatToMarkdown(message, citations);
  }

  return message;
};

const ChatBubbleComponent = ({
  llm,
  message,
  handleSubmit,
  privateChat,
}: {
  llm: Llm;
  message: Partial<Message>;
  handleSubmit: (prompt?: Prompt, input?: string) => void;
  privateChat?: boolean;
}) => {
  const profilePhoto: string | null = localStorage.getItem('profilePhoto')
    ? localStorage.getItem('profilePhoto')
    : null;
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector(selectConversations);
  const { selectedApp } = useSelector(selectAppStatus);
  const [isCodeView, setIsCodeView] = useState(true);
  const [hasHtmlCode, setHasHtmlCode] = useState(false);
  const analytics = useAnalytics();
  let route = '/chat';
  if (projectId) {
    route = `/intellio-advantage/${projectId}/chat`;
  }

  useEffect(() => {
    const isDalle = selectedApp == AppEnum.DALLE;
    setIsCodeView(isDalle ? false : true);
    setHasHtmlCode(isDalle ? true : false);
  }, [selectedApp]);

  const handleOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setAnchorContextMenu(null);
  };

  const createConvoFromMessage = (message: Message) => {
    handleCloseContextMenu();

    dispatch(setIsLoading(true));
    dispatch(postConvoFromMessage(message)).then(
      (action: PayloadAction<Conversation | unknown>) => {
        const newConversation = action.payload as Conversation;
        dispatch(getConversations());
        navigate(`${route}/${newConversation.id}`);
      }
    );
  };

  const regenerateResponse = (message: Message) => {
    handleCloseContextMenu();
    const messageId = message.id;
    analytics.regenerateResponse({ messageId: messageId, llm });
    dispatch(setIsLoading(true));
    dispatch(regenerateMessage({ message, llm })).then(
      (action: PayloadAction<Conversation | unknown>) => {
        const newConversation = action.payload as Conversation;
        dispatch(getConversations());
        navigate(`${route}/${newConversation.id}`);
      }
    );
  };

  return (
    <ChatCard
      sender={message.from ?? MessageFrom.AI}
      sx={{ opacity: isLoading ? '40%' : '100%', transition: '0.5s' }}
    >
      <>
        {message.from === MessageFrom.USER ? (
          profilePhoto ? (
            <ProfilePhoto src={profilePhoto} alt="Profile"></ProfilePhoto>
          ) : (
            <PersonIcon />
          )
        ) : (
          <div>
            {hasHtmlCode && message.id && (
              <Button onClick={() => setIsCodeView(!isCodeView)}>
                {isCodeView ? 'View HTML' : 'View Code'}
              </Button>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {isLoading ? (
                <SpinningChatIcon src={WmCompass} alt="West Monroe Compass" />
              ) : (
                <ChatIcon src={WmCompass} alt="West Monroe Compass" />
              )}
              {message.conversationId && !privateChat && (
                <Tooltip
                  id={`msg-opts-label-${message.id}`}
                  PopperProps={{ keepMounted: true }}
                  title={<Typography variant={'caption'}>Message options</Typography>}
                >
                  <span>
                    <IconButton
                      aria-labelledby={`msg-opts-label-${message.id}`}
                      sx={{ p: 0 }}
                      onClick={handleOpenContextMenu}
                      disabled={isLoading}
                    >
                      <MoreHoriz sx={{ width: '20px', height: '20px' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        )}
        <Menu
          anchorEl={anchorContextMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorContextMenu)}
          onClose={handleCloseContextMenu}
        >
          <MenuItem onClick={() => regenerateResponse(message as Message)}>
            <Typography textAlign="center">Regenerate Response</Typography>
          </MenuItem>
          <MenuItem onClick={() => createConvoFromMessage(message as Message)}>
            <Typography textAlign="center">Start New Conversation From Here</Typography>
          </MenuItem>
        </Menu>
        <ChatBubbleContainer sx={{ lineHeight: '1.5rem' }}>
          {message.from === MessageFrom.AI ? (
            message.azureCitations ? (
              // Both Conditions True
              <>
                {renderMessageWithCitations(
                  message.message ?? '',
                  message.citations ?? [],
                  message.azureCitations
                )}
              </>
            ) : (
              // Only MessageFromAI is true
              <ReactMarkdown
                linkTarget={'_blank'}
                remarkPlugins={[remarkGfm, remarkMath, supersub]}
                rehypePlugins={[rehypeKatex]}
                components={{
                  pre: ({ className, children }) => (
                    <CodePre className={className}>
                      <CodeCopyButton loading={isLoading}>{children}</CodeCopyButton>
                      {children}
                    </CodePre>
                  ),
                  table: (props) => (
                    <MarkdownTable {...props} handleSubmit={handleSubmit} loading={isLoading} />
                  ),
                  code({ inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    const language = match ? match[1] : null;

                    // Check the view mode to decide whether to render the iframe or the code block
                    // Only use the iframe if the browser supports sanbox on iframe
                    if (
                      !inline &&
                      language === 'html' &&
                      !isCodeView &&
                      'sandbox' in document.createElement('iframe')
                    ) {
                      const blob = new Blob([String(children)], { type: 'text/html' });
                      const src = URL.createObjectURL(blob);

                      return (
                        //DO NOT allow-same-origin for sandbox
                        <iframe
                          {...props}
                          src={src}
                          style={{ width: '100%', height: '500px' }}
                          sandbox="allow-scripts"
                        />
                      );
                    } else if (!inline && match) {
                      return (
                        <StyledSyntaxHighlighter
                          {...props}
                          language={match[1]}
                          style={a11yDark}
                          PreTag="div"
                        >
                          {String(children).replace(/\n$/, '')}
                        </StyledSyntaxHighlighter>
                      );
                    } else {
                      return (
                        <code {...props} style={{ wordWrap: 'normal' }} className={className}>
                          {children}
                        </code>
                      );
                    }
                  },
                }}
              >
                {renderMessage(message.message ?? '', message.citations ?? [])}
              </ReactMarkdown>
            )
          ) : (
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {message.files && Array.isArray(message.files)
                  ? message.files.map((uploadedFile: MessageFile, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard uploadedFile={uploadedFile} llm={llm} />
                      </div>
                    ))
                  : null}
              </div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{message.message}</p>
            </div>
          )}
        </ChatBubbleContainer>
      </>
    </ChatCard>
  );
};

export default ChatBubbleComponent;
