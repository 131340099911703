import { Container, styled, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import AssistantCard from '../../components/AIAccountCoach/AssistantCard';
import { selectAssistants } from '../../redux/reducers/assistantsReducer';

const MainDiv = styled('div')({
  display: 'flex',
  height: '100vh',
  overflowY: 'auto',
  div: {
    position: 'relative',
  },
});

const AssistantsContainer = styled(Container)({
  width: `100%`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  height: 'inherit',
  background: 'white',
});

const CardContainer = styled(Container)({
  display: 'flex',
  gap: '30px',
  flexWrap: 'wrap',
  paddingBottom: '20px',
});

const AssistantsLibrary = () => {
  const { assistants } = useSelector(selectAssistants);

  return (
    <MainDiv>
      <div
        style={{
          overflow: 'auto',
          width: '100%',
          minHeight: 'calc(100% - 64px)',
          background: 'white',
        }}
      >
        <AssistantsContainer>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            ExpertGPTs
          </Typography>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '30px',
              marginTop: '30px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            These assistants leverage the powerful capabilities of GPT architectures with human
            expertise resulting in more relevant and contextualized Al outputs.
          </Typography>

          <CardContainer>
            {assistants.map((assistant, index) => (
              <AssistantCard key={index} assistant={assistant} />
            ))}
          </CardContainer>
        </AssistantsContainer>
      </div>
    </MainDiv>
  );
};

export default AssistantsLibrary;
