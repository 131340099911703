import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import { TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import DealGPTCreateProjectContainer from './components/DealGPT/ViewModels/DealGPTCreateProjectContainer';
import { ExpertProtections, NigelProtections, useAuth } from './components/Providers/AuthProvider';
import { useFeatures } from './components/Providers/FeatureProvider';
import { useJoyride } from './components/Providers/JoyrideProvider';
import { useProjects } from './components/Providers/ProjectsProvider';
import AdminPage from './pages/AdminPage';
import AssistantForm from './pages/AIAccountCoach/AssistantForm';
import AssistantsLibrary from './pages/AIAccountCoach/AssistantsLibrary';
import DocumentUpload from './pages/AIAccountCoach/DocumentUpload';
import Interview from './pages/AIAccountCoach/Interview';
import Chat from './pages/Chat';
import DealGPT from './pages/DealGPT/DealGPT';
import DealGPTProject from './pages/DealGPT/DealGPTProject';
import Login from './pages/Login';
import PrivateChat from './pages/PrivateChat';
import ProfileSettings from './pages/ProfileSettings';
import PromptEdit from './pages/PromptEdit';
import Prompts from './pages/Prompts';
import ReleaseNotes from './pages/ReleaseNotes';
import SharedConversation from './pages/SharedConversation';
import { getAssistants } from './redux/actions/assistantsActions';
import { getConversations } from './redux/actions/conversationActions';
import { selectAppStatus, setCurrentApp, setLLM } from './redux/reducers/appStatusReducer';
import { setMaxNumTokens } from './redux/reducers/conversationReducer';
import { AppDispatch } from './redux/store';
import { AppEnum, DealGPTView } from './Types/enums';
import { GuidedSteps } from './Types/guides';
import { useIsMobile } from './util/useIsMobile';

const FullPageContainer = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    height: isMobile ? '100%' : '100vh',
    overflowY: 'hidden',
    position: 'relative',
  };
});

const titleElement = document.getElementById('title');
const iconElement = document.getElementById('icon') as HTMLLinkElement;
if (titleElement) {
  if (
    import.meta.env.VITE_EXPERTS_REDIRECT_ORIGIN_URI &&
    window.location.origin === import.meta.env.VITE_EXPERTS_REDIRECT_ORIGIN_URI
  ) {
    titleElement.innerText = 'Experts';
  } else {
    if (import.meta.env.VITE_ENVIRONMENT === 'prod') {
      titleElement.innerText = 'Nigel';
    } else if (import.meta.env.VITE_ENVIRONMENT === 'demo') {
      titleElement.innerText = 'Nigel - demo';
    } else if (import.meta.env.VITE_ENVIRONMENT === 'staging') {
      titleElement.innerText = 'Nigel - staging';
      iconElement.href = '/favicongrey.png';
    } else if (import.meta.env.VITE_ENVIRONMENT === 'dev') {
      titleElement.innerText = 'Nigel - dev';
      iconElement.href = '/favicongrey.png';
    } else if (import.meta.env.VITE_ENVIRONMENT === 'local') {
      titleElement.innerText = 'Nigel - local';
      iconElement.href = '/favicongrey.png';
    }
  }
}

function ProvidedApp() {
  const { currentUser } = useAuth();
  const [gleanApplicationId, setGleanApplicationId] = useState<string>('');
  const [outageBannerMessage, setOutageBannerMessage] = useState<string>('');
  const { tourState, setTourState } = useJoyride();
  const features = useFeatures();
  const navigate = useNavigate();
  const { projects } = useProjects();
  const dispatch = useDispatch<AppDispatch>();
  const { selectedApp, llm } = useSelector(selectAppStatus);
  const { instance } = useMsal();

  useEffect(() => {
    const bannerMessage = import.meta.env.VITE_OUTAGE_BANNER;
    if (bannerMessage) {
      setOutageBannerMessage(bannerMessage);
    }
  }, []);

  // use effect that checks the current url and uses setCurrentApp to set the current app
  useEffect(() => {
    if (window.location.pathname.includes('intellio-advantage')) {
      dispatch(setCurrentApp(AppEnum.DEAL_GPT));
    }
  }, [navigate, dispatch]);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { lifecycle, action } = data;
    if (lifecycle === 'complete') {
      if (action === 'close') {
        // Cancel tour if user closes the box at any point
        enqueueSnackbar(
          'Tour cancelled, click on the question mark on the Intellio® Advantage homepage to start it again',
          {
            variant: 'info',
            autoHideDuration: 10000,
          }
        );
        setTourState({
          ...tourState,
          ...{ steps: [], run: false, tourActive: false, stepIndex: 0 },
        });
      }
      if (action === 'prev') {
        if (tourState.stepIndex === 2) {
          setTourState({ ...tourState, ...{ run: false } });
          navigate(`/intellio-advantage`);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else if (tourState.stepIndex === 5) {
          setTourState({ ...tourState, ...{ run: false } });
          history.back();
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const element = document.querySelector('.categories-root div:first-child') as HTMLElement;
          if (element) {
            if (!element.classList.contains('Mui-expanded')) {
              element.click();
              await new Promise((resolve) => setTimeout(resolve, 1000));
            }
          }
        }
        setTourState({ ...tourState, ...{ stepIndex: tourState.stepIndex - 1, run: true } });
      }
      if (action === 'next') {
        if (tourState.stepIndex === 0) {
          // Dynamically load the target to the first project in the list
          GuidedSteps[1].target = `#projectid${projects[0].id}`;
        } else if (tourState.stepIndex === 1) {
          setTourState({ ...tourState, ...{ run: false } });
          navigate(`/intellio-advantage/${projects[0].id}`);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else if (tourState.stepIndex === 3) {
          const element = document.querySelector('.categories-root div:first-child') as HTMLElement;
          if (element) {
            if (!element.classList.contains('Mui-expanded')) {
              element.click();
            }
            setTourState({ ...tourState, ...{ run: false } });
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        } else if (tourState.stepIndex === 4) {
          setTourState({ ...tourState, ...{ run: false } });
          navigate(`/intellio-advantage/${projects[0].id}/chat`);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        setTourState({ ...tourState, ...{ stepIndex: tourState.stepIndex + 1, run: true } });

        // handle the tour being over
        if (tourState.stepIndex === GuidedSteps.length - 1) {
          setTourState({
            ...tourState,
            ...{ steps: [], run: false, tourActive: false, stepIndex: 0 },
          });
          navigate(`/intellio-advantage`);
        }
        // TODO might need this
        // await updateUserMetadata({
        //   ...currentUser?.metadata,
        //   ...{ tourCompleted: true },
        // } as UserMetadata);
      }
    }
  };

  const initialSetup = useCallback(() => {
    dispatch(getConversations());
    dispatch(setLLM(currentUser?.metadata?.defaultLlm || 'wm-prompt-lib-gpt-4o-128k-global'));
    dispatch(getAssistants());
  }, [dispatch, currentUser?.metadata?.defaultLlm]);

  useEffect(() => {
    initialSetup();
  }, [instance, initialSetup]);

  useEffect(() => {
    switch (llm) {
      case 'wm-prompt-lib-gpt-35-turbo-16k':
      case 'wm-prompt-lib-gpt-4':
        dispatch(setMaxNumTokens(16000));
        break;
      case 'wm-prompt-lib-gpt-4-32k':
        dispatch(setMaxNumTokens(32000));
        break;
      case 'wm-prompt-lib-gpt-4-turbo-128k':
      case 'wm-prompt-lib-gpt-4o-128k-global':
        dispatch(setMaxNumTokens(128000));
        break;
      default:
        dispatch(setMaxNumTokens(16000));
        break;
    }
  }, [dispatch, llm]);

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={tourState.run}
        stepIndex={tourState.stepIndex}
        steps={tourState.steps}
        disableOverlayClose={true}
        locale={{
          last: 'OK',
        }}
        styles={{
          buttonNext: {
            backgroundColor: '#092b49',
            borderRadius: '0px',
          },
          buttonBack: {
            color: '#092b49',
          },
          beaconInner: {
            backgroundColor: '#092b49',
          },
          beaconOuter: {
            borderColor: '#092b49',
            backgroundColor: 'rgba(9, 43, 73, 0.5)',
          },
          options: {
            zIndex: 9999,
          },
        }}
      />
      <FullPageContainer>
        {outageBannerMessage && (
          <div
            style={{
              width: '100%',
              minHeight: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'red',
            }}
          >
            <Typography sx={{ color: 'white', textAlign: 'center' }}>
              {outageBannerMessage}
              <br />
              ⚠️ Please try using Nigel at a later time. ⚠️
            </Typography>
          </div>
        )}
        {(features.gpt4 ||
          features.gpt4_turbo_128k ||
          features.gpt35_turbo_16k ||
          features.gpt4o_128k) &&
          selectedApp !== AppEnum.DEAL_GPT && (
            <div
              style={{
                position: 'absolute',
                top: '76px',
                right: '20px',
                display: currentUser ? 'flex' : 'none',
                alignItems: 'center',
                background: 'white',
                zIndex: '10',
                borderRadius: '4px',
              }}
            >
              {llm === 'glean' && (
                <TextField
                  placeholder="Application ID"
                  size="small"
                  value={gleanApplicationId}
                  onChange={(event) => setGleanApplicationId(event.target.value)}
                />
              )}
            </div>
          )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/chat"
            element={
              <NigelProtections>
                <Chat gleanApplicationId={gleanApplicationId} />
              </NigelProtections>
            }
          />
          <Route
            path="/chat/:conversationId"
            element={
              <NigelProtections>
                <Chat gleanApplicationId={gleanApplicationId} />
              </NigelProtections>
            }
          />
          <Route
            path="/chat/shared/:conversationId"
            element={
              <NigelProtections>
                <SharedConversation />
              </NigelProtections>
            }
          />
          <Route
            path="/prompts"
            element={
              <NigelProtections>
                <Prompts myPrompts={false} />
              </NigelProtections>
            }
          />
          <Route
            path="/my-prompts"
            element={
              <NigelProtections>
                <Prompts myPrompts={true} />
              </NigelProtections>
            }
          />
          <Route
            path="/admin-page"
            element={
              <NigelProtections>
                <AdminPage />
              </NigelProtections>
            }
          />
          <Route
            path="/profile-settings"
            element={
              <NigelProtections>
                <ProfileSettings />
              </NigelProtections>
            }
          />
          <Route
            path="/release-notes"
            element={
              <NigelProtections>
                <ReleaseNotes />
              </NigelProtections>
            }
          />
          <Route
            path="/prompt-edit"
            element={
              <NigelProtections>
                <PromptEdit />
              </NigelProtections>
            }
          />
          <Route
            path="/prompt-edit/:promptId"
            element={
              <NigelProtections>
                <PromptEdit />
              </NigelProtections>
            }
          />
          <Route
            path="/chat-private"
            element={
              <NigelProtections>
                <PrivateChat gleanApplicationId={gleanApplicationId} />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage"
            element={
              <NigelProtections>
                <DealGPT />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/:projectId"
            element={
              <NigelProtections>
                <DealGPTProject selectedView={DealGPTView.DISCOVERY} />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/:projectId/analysis/:scope"
            element={
              <NigelProtections>
                <DealGPTProject selectedView={DealGPTView.ANALYSIS} />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/:projectId/scope-areas"
            element={
              <NigelProtections>
                <DealGPTProject selectedView={DealGPTView.SCOPE_AREAS} />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/:projectId/chat"
            element={
              <NigelProtections>
                <DealGPTProject selectedView={DealGPTView.CHAT} />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/:projectId/chat/:conversationId"
            element={
              <NigelProtections>
                <DealGPTProject selectedView={DealGPTView.CHAT} />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/create-project"
            element={
              <NigelProtections>
                <DealGPTCreateProjectContainer />
              </NigelProtections>
            }
          />
          <Route
            path="/intellio-advantage/edit-project/:projectId"
            element={
              <NigelProtections>
                <DealGPTCreateProjectContainer />
              </NigelProtections>
            }
          />
          <Route
            path="/account-coach/interview"
            element={
              <ExpertProtections>
                <Interview />
              </ExpertProtections>
            }
          />
          <Route
            path="/account-coach/upload"
            element={
              <ExpertProtections>
                <DocumentUpload />
              </ExpertProtections>
            }
          />
          <Route
            path="/assistants"
            element={
              <ExpertProtections>
                <AssistantsLibrary />
              </ExpertProtections>
            }
          />
          <Route
            path="/assistants/:assistantId"
            element={
              <ExpertProtections>
                <AssistantForm />
              </ExpertProtections>
            }
          />
          <Route
            path="*"
            element={(() => {
              if (!import.meta.env.VITE_EXPERTS_REDIRECT_ORIGIN_URI) {
                // No experts redirect URI defined, default to Nigel
                return <Navigate to="/chat" replace={true} />;
              } else if (
                window.location.origin === import.meta.env.VITE_EXPERTS_REDIRECT_ORIGIN_URI
              ) {
                // Experts redirect URI, default to account coach interview form
                return <Navigate to="/account-coach/interview" replace={true} />;
              } else {
                // Default is Nigel
                return <Navigate to="/chat" replace={true} />;
              }
            })()}
          />
        </Routes>
      </FullPageContainer>
    </>
  );
}

export default ProvidedApp;
