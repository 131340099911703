import { enqueueSnackbar } from 'notistack';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getProjects } from '../../services/dealGPT';
import type { ProjectType } from '../../Types/dealGPT';
import { UserRole } from '../../Types/enums';
import { useAuth } from './AuthProvider';

interface ProjectsContextType {
  projects: ProjectType[];
  hasProjects: boolean;
  setShouldFetch: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  protectedSetAdminShowAll: (value: boolean) => boolean;
  totalPages: number;
  isLoading: boolean;
  adminShowAll: boolean;
  protectedSetShowArchived: (value: boolean) => boolean;
  showArchived: boolean;
  toggleArchiveChecked: boolean;
}

export const ProjectsContext = createContext<ProjectsContextType | null>(null);

export const ProjectsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [hasProjects, setHasProjects] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [adminShowAll, setAdminShowAll] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [toggleArchiveChecked, setToggleArchiveChecked] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fetchTokens, currentUser } = useAuth();

  const pageSize = 6;

  const protectedSetAdminShowAll = useCallback(
    (value: boolean) => {
      let resultingValue = false;
      if (value && currentUser?.roles.includes(UserRole.DEALGPT_ADMIN)) {
        setAdminShowAll(true);
        resultingValue = true;
      } else {
        if (value) {
          enqueueSnackbar('You do not have the required permissions to view all projects', {
            variant: 'error',
          });
        }
        resultingValue = false;
        setAdminShowAll(false);
        if (projects.length < page) {
          setPage(1);
        }
      }
      setShouldFetch(true);
      return resultingValue;
    },
    [currentUser?.roles, page, projects.length]
  );

  const protectedSetShowArchived = useCallback((value: boolean) => {
    //If the toggle in te UI is clicked means that if the provider returns no archived projects it should not redirect to NoAccess view.
    setToggleArchiveChecked(true);
    let resultingValue = false;
    if (value) {
      setShowArchived(true);
      resultingValue = true;
    } else {
      resultingValue = false;
      setShowArchived(false);
    }
    setShouldFetch(true);
    return resultingValue;
  }, []);

  const value = useMemo(
    () => ({
      projects,
      hasProjects,
      setShouldFetch,
      page,
      setPage,
      totalPages,
      isLoading,
      protectedSetAdminShowAll,
      protectedSetShowArchived,
      adminShowAll,
      showArchived,
      toggleArchiveChecked,
    }),
    [
      projects,
      hasProjects,
      setShouldFetch,
      page,
      setPage,
      totalPages,
      isLoading,
      protectedSetAdminShowAll,
      protectedSetShowArchived,
      adminShowAll,
      showArchived,
      toggleArchiveChecked,
    ]
  );

  useEffect(() => {
    const fetchProjects = async () => {
      let retryCount = 0;
      while (retryCount < 3) {
        try {
          setIsLoading(true);
          const projectsResponse = await getProjects({
            page,
            pageSize,
            adminShowAll,
            showArchived,
          });
          if (projectsResponse._isSuccess) {
            if (projectsResponse._data && projectsResponse._data.length > 0) {
              setProjects(projectsResponse._data);
              setHasProjects(true);
              setTotalPages(Math.ceil(projectsResponse._data[0].userProjectsCount / pageSize));
            } else {
              setHasProjects(false);
              setProjects([]);
            }
            break;
          } else {
            await fetchTokens();
            retryCount++;
          }
        } catch (error) {
          await fetchTokens();
          retryCount++;
        }
      }
      if (retryCount === 3) {
        enqueueSnackbar('Failed to fetch projects', {
          variant: 'error',
        });
      }
      setIsLoading(false);
    };

    if (shouldFetch) {
      fetchProjects();
      setShouldFetch(false);
    }
  }, [
    fetchTokens,
    shouldFetch,
    setShouldFetch,
    pageSize,
    page,
    setIsLoading,
    protectedSetAdminShowAll,
    protectedSetShowArchived,
    adminShowAll,
    showArchived,
  ]);

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};

export const useProjects = (): ProjectsContextType => {
  const projectsContext = useContext(ProjectsContext);

  if (!projectsContext) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }

  return projectsContext;
};
