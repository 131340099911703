import { css, styled } from '@mui/system';

const StyledMarkdownTable = styled('div')(
  css({
    borderSpacing: '0',
    borderCollapse: 'collapse',
    display: 'block',
    marginTop: '0',
    marginBottom: '0',
    width: 'max-content',
    maxWidth: '100%',
    overflow: 'auto',

    '& table': {
      width: '100%',
      maxWidth: '100%',
      marginBottom: '1rem',
      borderCollapse: 'collapse',
    },

    '& tr': {
      backgroundColor: '#ffffff',
      borderTop: '1px solid hsla(210, 18%, 87%, 1)',

      '&:nth-child(2n)': {
        backgroundColor: '#f6f8fa',
      },
    },

    '& td, & th': {
      padding: '6px 13px',
      border: '1px solid #d0d7de',
    },
  })
);

export default StyledMarkdownTable;
