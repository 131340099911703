import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { Assistant } from '../../Types/AIAccountCoach/accountCoach';
import { getAssistant, getAssistants } from '../actions/assistantsActions';

interface AssistantsState {
  assistants: Assistant[];
  currentAssistant?: Assistant;
}

const initialState: AssistantsState = {
  assistants: [],
  currentAssistant: undefined,
};

const assistantsSlice = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    setAssistants: (state, action: PayloadAction<Assistant[]>) => {
      state.assistants = action.payload;
    },
    setCurrentAssistant: (state, action: PayloadAction<Assistant>) => {
      state.currentAssistant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssistants.fulfilled, (state, action: PayloadAction<Assistant[]>) => {
        state.assistants = action.payload;
      })
      .addCase(getAssistant.fulfilled, (state, action: PayloadAction<Assistant>) => {
        state.currentAssistant = action.payload;
      });
  },
});

export const { setAssistants, setCurrentAssistant } = assistantsSlice.actions;

export const selectAssistants = (state: RootState) => state.assistants;

export default assistantsSlice.reducer;
